<template>
  <g>
    <path
      :style="`stroke:none;fill-rule:evenodd;fill:${color};fill-opacity:1;`"
      d="M 19.335938 0.265625 L 12.265625 0.265625 C 11.644531 0.265625 10.835938 0.601562 10.398438 1.042969 L 0.679688 10.390625 C 0.128906 10.941406 0.128906 11.828125 0.675781 12.371094 L 8.628906 20.324219 C 9.171875 20.871094 10.058594 20.871094 10.601562 20.324219 L 19.964844 10.59375 C 20.398438 10.164062 20.734375 9.355469 20.734375 8.734375 L 20.734375 1.664062 C 20.734375 0.894531 20.105469 0.265625 19.335938 0.265625 Z M 12.265625 1.289062 L 19.335938 1.289062 C 19.539062 1.289062 19.710938 1.457031 19.710938 1.664062 L 19.710938 8.734375 C 19.710938 9.085938 19.484375 9.632812 19.234375 9.878906 L 9.871094 19.609375 C 9.734375 19.746094 9.496094 19.746094 9.355469 19.601562 L 1.398438 11.644531 C 1.253906 11.503906 1.253906 11.265625 1.398438 11.121094 L 11.113281 1.773438 C 11.367188 1.515625 11.917969 1.289062 12.265625 1.289062 Z M 12.265625 1.289062 "
    />
    <path
      :style="`stroke:none;fill-rule:evenodd;fill:${color};fill-opacity:1;`"
      d="M 17.570312 4.753906 C 17.570312 5.488281 16.976562 6.082031 16.246094 6.082031 C 15.511719 6.082031 14.917969 5.488281 14.917969 4.753906 C 14.917969 4.023438 15.511719 3.429688 16.246094 3.429688 C 16.976562 3.429688 17.570312 4.023438 17.570312 4.753906 "
    />
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
